@import url("https://p.typekit.net/p.css?s=1&k=eht2nwg&ht=tk&f=47890.47891.47892.47893.47894&a=56266181&app=typekit&e=css");

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Clash Display";
  src: url("../fonts/ClashDisplay-Medium.woff2") format("woff2"),
    url("../fonts/ClashDisplay-Medium.woff") format("woff"), url("../fonts/ClashDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url("../fonts/ClashDisplay-Regular.woff2") format("woff2"),
    url("../fonts/ClashDisplay-Regular.woff") format("woff"),
    url("../fonts/ClashDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url("../fonts/ClashDisplay-Semibold.woff2") format("woff2"),
    url("../fonts/ClashDisplay-Semibold.woff") format("woff"),
    url("../fonts/ClashDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
