@import "~bootstrap/scss/bootstrap.scss";
@import "./fonts.scss";
@import "./helpers.scss";
@import "./form.scss";

$body-bg: #302c38;

html,
body {
  margin: 0;
  padding: 0;
}

:root {
  --shift: 0;
  --x: 0;
  --y: 0;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: $body-bg;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
  &:hover {
    color: inherit;
    opacity: 0.6;
    transition: all ease 0.3s;
  }
}

@keyframes fader {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(150px);
  }
  50% {
    transform: translateX(150px);
  }
  50.01% {
    transform: translateX(-150px);
  }
  70% {
    transform: translateX(0);
  }
}

@keyframes reveal {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  40% {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
